<template>
    <div id="account-credentials-content">
        <div id="account-credentials-form">
            <h1 class="change-password-header">Reset Password</h1>
            <div class="reset-pasword-message">
                <div>
                    <b><a href="https://account.texasoncourse.org/passwords/reset" class="change-password-link">Click here</a></b>  to change your password. 
                </div>
                <div>
                    The link will open up in a new tab. Your new password will be in effect the next time you log in.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

h1.change-password-header {
    margin-top: 67px;
    text-align: left;
    margin-bottom: 37px;
}

.reset-password-message {
    text-align: left;
    color: #3A3A3A;
    font-family: Lato;
    font-size: 18px;
}

.change-password-link {
    color: #1a6edb;
    margin-right: 2px;
}

.reset-password-message div:nth-child(1) {
    margin-bottom: 21px;
} 

</style>