<template>
  <div>
    <Header></Header>
    <div id="profile-content" class="container-sm mt-sm-5 profile-content">
      <div id="profile-form">
        <div id="profile-header">
          <button
            @click="profileToggle('tabProfile')"
            class="profile-header-button"
            v-bind:class="{ line: showProfile }"
          >
            {{ lang_stanza.tab_title_profile }}
          </button>

          <!--
              <button @click="profileToggle('tabCredentials')" class="profile-header-button"
                      to="profile#credentials"
                      v-bind:class="{ line:showCredentials }">
                Edit Password
              </button>
              -->

          <button
            @click="profileToggle('tabPreferences')"
            class="profile-header-button"
            v-bind:class="{ line: showPreferences }"
          >
            {{ lang_stanza.tab_title_preferences }}
          </button>
        </div>
        <div id="center">
          <account-preferences
            ref="tabPreferences"
            v-show="this.showPreferences"
          >
          </account-preferences>

          <account-credentials
            ref="tabCredentials"
            v-show="this.showCredentials"
          >
          </account-credentials>

          <account-profile
            ref="tabProfile"
            v-show="this.showProfile"
          ></account-profile>
        </div>
        <div class="col-2"></div>
        <div class="profile-footer">
          <div
            id="Alert-prof"
            tabindex="-1"
            v-if="alert"
            :class="'alert ' + alert.type"
          >
            {{ alert.message }}
            <router-link
              :to="{ path: `/${lang_info.lang_code}/home` }"
              v-if="alert.type != 'alert-danger'"
              >Return to your home page</router-link
            >
          </div>
          <!-- <div
            v-for="(error, err_idx) in this.submit_error_list"
            v-bind:key="err_idx"
            style="color: red"
          >
            {{ error.error_message }}
          </div> -->
          <div id="profile-footer-home">
            <!-- <button to="home" id="homepage-button">Back to Homepage</button> -->
            <!-- <router-link
              tag="button"
              :to="{ path: `/${lang_info.lang_code}/home` }"
              id="homepage-button"
             > 
             {{ lang_stanza.back_to_homepage }}</router-link> -->
            <button @click="updateSwitch()" id="update-button">
              {{ lang_stanza.update }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import AccountPreferences from "../components/AccountPreferences";
import AccountCredentials from "../components/AccountCredentials";
import AccountProfile from "../components/AccountProfile";
import { router, sessionStorage } from "../_helpers";
import { validation } from "../_helpers/validation";
import { mapActions, mapState } from "vuex";
import dateformat from "dateformat";

export default {
  data() {
    return {
      showProfile: true,
      showCredentials: false,
      showPreferences: false,
      submit_error_list: [],
      lang_stanza_key: "profile",
      lang_stanza: {},
      lang_info: {},
    };
  },
  components: {
    AccountPreferences,
    AccountCredentials,
    AccountProfile,
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    let userSessionProfile = sessionStorage.decodeUserSession();
    if (!userSessionProfile || !userSessionProfile.sub) {
      await router.push("`/${lang_info.lang_code}/login`");
    }
    let oidc_id = userSessionProfile.sub;
    await this.getUserPreferences({
      oidc_id: oidc_id,
      lang_code: this.lang_info.lang_code,
    });
    await this.getUserProfileByOidc(oidc_id, this.lang_info.lang_code);
    document.body.style.backgroundColor = "#FAFAFA";
  },
  updated() {
    let alert = document.getElementById("Alert");

    if (alert) {
      alert.focus();
    }
  },
  methods: {
    ...mapActions("account", [
      "updateUserProfile",
      "getUserProfileByEmail",
      "getUserProfileByOidc",
      "persistDateUpdate",
    ]),
    ...mapActions("preferences", [
      "persist_user_districts",
      "persist_user_schools",
      "persist_user_pathway_selections",
      "updateUserGradePathwayList",
      "getUserPreferences",
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
    ...mapActions("language", [
      "getLangInfo",
      "getLangStanza",
      "langKeyLookup",
    ]),
    ...mapActions("alert", ["clear", "error", "success"]),
    async updateSwitch() {
      document.getElementById("Alert-prof").style.display = "grid";
      await this.clear();
      if (this.userProfile) {
        await this.updateProfile();
      }
      if (this.credentials) {
        await this.updateProfile();
      }
      if (this.user_grade_pathway_list) {
        // await this.updatePreferences();
        await this.updateProfile();
      }
      // this.$forceUpdate();
    },
    isValidName(name) {
      if (!name || name === "" || name.length < 2) {
        return false;
      }
      if (name.match("[^a-zA-Z]+")) {
        return false;
      }
      return true;
    },
    async updateProfile() {
      let sessionUserProfile = sessionStorage.decodeUserSession();
      if (!sessionUserProfile || !sessionUserProfile.sub) {
        return false;
      }
      let oidc_id = sessionUserProfile.sub;

      await this.getUserProfileByOidc(oidc_id);
      if (!sessionUserProfile || !sessionUserProfile.sub) {
        return false;
      }

      let firstName = this.$refs.tabProfile.firstName.replace(/^\s+|\s+$/g, "");
      let lastName = this.$refs.tabProfile.lastName.replace(/^\s+|\s+$/g, "");

      this.$refs.tabProfile.firstName = firstName;
      this.$refs.tabProfile.lastName = lastName;

      let emailNotifications =
        this.$refs.tabPreferences.userPreferences.emailNotifications;
      let is_email_sub;
      this.submit_error_list = [];

      if (!this.isValidName(firstName)) {
        this.submit_error_list.push({
          error_key: "invalid-value",
          error_message: "err_first_name_non_alpha",
        });
      }

      if (!this.isValidName(lastName)) {
        this.submit_error_list.push({
          error_key: "invalid-value",
          error_message: this.langLookup("err_last_name_non_alpha"),
        });
      }

      if (this.user_school_selections.length < 1) {
        this.submit_error_list.push({
          error_key: "insufficient-selections",
          error_message: this.langLookup("err_no_district_school"),
        });
      }

      if (this.user_pathway_selections.length < 1) {
        this.submit_error_list.push({
          error_key: "insufficient-selections",
          error_message: this.langLookup("err_no_grade_pathway"),
        });
      }

      if (this.submit_error_list.length > 0) {
        this.submit_error_list.push({
          error_key: "errors-exist",
          error_message: this.langLookup("err_errors_exist"),
        });
        await this.error(this.langLookup("err_errors_exist"));
        return;
      }

      if (emailNotifications) {
        is_email_sub = 1;
      } else {
        is_email_sub = 0;
      }

      const params = `first_name = '${firstName}', last_name = '${lastName}', is_email_sub = ${is_email_sub}`;

      await this.updateUserProfile({ oidc_id: oidc_id, params: params });

      await this.persist_user_pathway_selections({
        oidc_id: oidc_id,
        user_pathway_selections: this.user_pathway_selections,
      });
      await this.persist_user_schools({
        oidc_id: oidc_id,
        user_schools: this.user_school_selections,
      });
      // Unnecessary to do an extra database persistence call just for districts.
      // The school selection data will have district id associations for each school.
      // await this.persist_user_districts( {"oidc_id": oidc_id, "user_districts": this.user_district_selections });

      let now = new Date();
      let ts_update = dateformat(now, "yyyy-mm-dd HH:MM:ss.l");

      let packet_dict = {
        oidc_id: sessionUserProfile.sub,
        ts_update: ts_update,
      };
      await this.persistDateUpdate(packet_dict);

      await this.getUserPreferences({
        oidc_id: oidc_id,
        lang_code: this.lang_info.lang_code,
      });
      await this.getUserProfileByOidc({
        oidc_id: oidc_id,
        lang_code: this.lang_info.lang_code,
      });
      //Clean up
      // this.$refs.tabProfile.firstName = ''
      // this.$refs.tabProfile.lastName = ''

      return true;
    },
    async updateCredentials() {},
    async updatePreferences() {
      if (this.submit_error_list.length > 0) {
        this.submit_error_list.push({
          error_key: "errors-exist",
          error_message: "Errors exist. Profile and preferences were not saved",
        });
        return;
      }

      let sessionUserProfile = sessionStorage.decodeUserSession();
      if (!sessionUserProfile || !sessionUserProfile.sub) {
        return false;
      }
      let oidc_id = sessionUserProfile.sub;

      await this.persist_user_pathway_selections({
        oidc_id: oidc_id,
        user_pathway_selections: this.user_pathway_selections,
      });
    },
    profileToggle(toggle) {
      switch (toggle) {
        case "tabProfile":
          this.showProfile = true;
          this.showCredentials = false;
          this.showPreferences = false;
          break;
        case "tabCredentials":
          this.showProfile = false;
          this.showCredentials = false;
          this.showPreferences = false;
          break;
        case "tabPreferences":
          this.showProfile = false;
          this.showCredentials = false;
          this.showPreferences = true;
          break;
      }
    },
  },
  computed: {
    ...mapState("preferences", [
      "grades",
      "user_grade_pathway_list",
      "user_district_selections",
      "user_pathway_selections",
      "user_school_selections",
    ]),
    ...mapState("account", ["userProfile"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    $route: {
      handler() {
        switch (this.$route.hash) {
          case "#credentials":
            this.showProfile = false;
            this.showCredentials = false;
            this.showPreferences = false;
            break;
          case "#preferences":
            this.showProfile = false;
            this.showCredentials = false;
            this.showPreferences = true;
            break;
          default:
            this.showProfile = true;
            this.showCredentials = false;
            this.showPreferences = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 769px) {
  .profile-content {
    background: #ffffff;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    max-height: 1269px;
    max-width: 584px;
    margin-top: 104px !important;
    margin-bottom: 120px;
  }

  .container-sm {
    background-color: transparent;
  }

  #center {
    margin-right: 64px;
    margin-left: 64px;
  }

  #profile-form {
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
  }

  #Alert-prof {
    top: 48px;
    max-width: 456px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    white-space: pre;
    margin-top: -50px;
    display: none;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .dropdown {
    position: relative;
    width: 100%;
  }

  .vs__dropdown-menu {
    max-width: 100% !important;
  }

  .dropdown-input,
  .dropdown-selected {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid lightgray;
    background: white;
    line-height: 1.5em;
    outline: none;
    text-align: left;
    margin-bottom: 20px;
  }

  .dropdown-input:focus,
  .dropdown-selected:hover {
    background: #fff;
    border-color: #e2e8f0;
  }

  .dropdown-input::placeholder {
    opacity: 0.7;
  }

  .dropdown-selected {
    font-weight: bold;
    cursor: pointer;
  }
  .dropdown-list {
    position: absolute;
    z-index: 2;
    width: 448px;
    height: 96px;
    margin-top: -30px;
    // overflow-y: auto;
    background: #ffffff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    //column-count: 3;
    // border: 1px solid blue;
    font-size: 14px;
    column-gap: 0px;
    // padding-right: 20px;
    // column-rule: 1px solid black;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    // border: 1px solid red;
    padding: 16px 12px 16px 12px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #b3b3b3;
    border-radius: 0px 0px 4px 4px;
  }

  .dropdown-list {
    position: absolute;
    z-index: 2;
    width: 448px;
    height: 96px;
    margin-top: -30px;
    // overflow-y: auto;
    background: #ffffff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    //column-count: 3;
    // border: 1px solid blue;
    font-size: 14px;
    column-gap: 0px;
    // padding-right: 20px;
    // column-rule: 1px solid black;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    // border: 1px solid red;
    padding: 16px 12px 16px 12px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #b3b3b3;
    border-radius: 0px 0px 4px 4px;
  }

  #dropdown-list {
    color: red;
  }

  .dropdown-item {
    display: flex;
    width: 100%;
    padding: 11px 16px;
    cursor: pointer;
    background-color: transparent !important;
  }

  .dropdown-item:hover {
    //background: #edf2f7;
  }

  #profile-header {
    width: 100%;
    background: #036cae;
    position: relative;
    border-radius: 6px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .profile-footer {
    width: 100%;
    //max-height: 200px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    display: block;
    margin-top: -48px;
  }

  #update-button {
    width: 159px;
    height: 60px;
    background: #c24c21;
    border: 2px solid #c24c21;
    border-radius: 16px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 32px;
    margin-bottom: 64px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  #update-button:hover {
    background: #ffffff;
    border: 2px solid #c24c21;
    color: #c24c21;
  }

  #update-button:active {
    background: #a9431d;
    border: 2px solid #a9431d;
  }

  #homepage-button {
    color: #333333;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    height: 48px;
    width: 213px;
    border: 1px solid #0d2b5a;
    border-radius: 16px;
    background-color: transparent;
    margin-bottom: 56px;
    margin-right: 20px;
  }

  #homepage-button:hover {
    background-color: #f15a2a;
    border: 2px solid #f15a2a;
    color: #ffffff;
  }

  #homepage-button:active {
    background-color: #f15a2a;
    border: 2px solid #f15a2a;
    color: #ffffff;
  }

  #profile-footer-home {
    text-align: center;
    margin-top: 80px;
    display: block;
  }

  .profile-header-button {
    background: transparent;
    border: none;
    padding: 18px 0px;
    // margin-right: 45px;
    color: #ffffff;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-left: 34px;
    margin-right: 34px;
  }

  // .profile-header-button:nth-of-type(1) {
  //   margin-left: 74px;
  // }

  .line {
    outline: none;
    border-bottom: 2px solid #f15a2a;
  }

  .col-8 {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  #Alert-prof {
    top: 48px;
    max-width: 450px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    white-space: pre;
    margin-top: -50px;
    display: none;
    margin-top: -10px;
    margin-bottom: 78px;
  }
}

@media (min-width: 376px) and (max-width: 768px) {
  .profile-content {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    max-height: 1269px;
    margin-bottom: 120px;
    max-width: 584px;
    width: 80%;
  }

  .form-control {
    max-width: 456px;
    width: 100%;
  }

  .container-sm {
    background-color: transparent;
  }

  #center {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  #profile-form {
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
    margin-top: 104px;
  }

  // #Alert-prof {
  //   top: 20px;
  //   // width: 440px;
  //   display: flex;
  //   flex-direction: column;
  //   white-space: pre;
  //   margin: 43px 10px 128px 10px;
  //   padding: 0 0 0 0;
  //   display: none;
  // }

  .dropdown {
    position: relative;
    width: 100%;
    // max-width: 400px;
  }

  .vs__dropdown-menu {
    max-width: 100% !important;
  }

  .dropdown-input,
  .dropdown-selected {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid lightgray;
    background: white;
    line-height: 1.5em;
    outline: none;
    text-align: left;
    margin-bottom: 20px;
  }

  .dropdown-input:focus,
  .dropdown-selected:hover {
    background: #fff;
    border-color: #e2e8f0;
  }

  .dropdown-input::placeholder {
    opacity: 0.7;
  }

  .dropdown-selected {
    font-weight: bold;
    cursor: pointer;
  }

  .dropdown-list {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 136px;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    padding: 16px 16px 16px 16px;
    margin: -30px 0 0 0;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #b3b3b3;
    border-radius: 0px 0px 4px 4px;
  }

  .dropdown-item {
    display: flex;
    width: 100%;
    padding: 11px 16px;
    cursor: pointer;
    background-color: transparent !important;
  }

  .dropdown-item:hover {
    //background: #edf2f7;
  }

  .caret {
    font-weight: bolder;
    font-size: 10pt;
    color: gray;
    float: right;
    margin: 0;
    padding: 0;
  }

  #profile-header {
    width: 100%;
    background: #036cae;
    position: relative;
    border-radius: 6px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .profile-footer {
    width: 100%;
    display: block;
    flex-direction: row;
  }

  #update-button {
    width: 159px;
    height: 60px;
    background: #c24c21;
    border: 2px solid #c24c21;
    border-radius: 16px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    display: block;
    margin-bottom: 64px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  #update-button:hover {
    background: #ffffff;
    border: 2px solid #c24c21;
    color: #c24c21;
  }

  #update-button:active {
    background: #a9431d;
    border: 2px solid #a9431d;
  }

  #homepage-button {
    color: #333333;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    height: 48px;
    width: 200px;
    border: 1px solid #0d2b5a;
    border-radius: 16px;
    background-color: transparent;
    margin-bottom: 56px;
    margin-top: 30px;
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #homepage-button:hover {
    background-color: #f15a2a;
    border: 2px solid #f15a2a;
    color: #ffffff;
  }

  #homepage-button:active {
    background-color: #f15a2a;
    border: 2px solid #f15a2a;
    color: #ffffff;
  }

  #profile-footer-home {
    text-align: center;
    margin-top: 0px !important;
    // display: flex;
    // justify-content: center;
    display: block;
  }

  .profile-header-button {
    background: transparent;
    border: none;
    padding: 18px 0px;
    // margin-right: 45px;
    color: #ffffff;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-left: 25px;
    margin-right: 25px;
  }

  // .profile-header-button:nth-of-type(1) {
  //   margin-left: 74px;
  // }

  .line {
    outline: none;
    border-bottom: 2px solid #f15a2a;
  }

  .col-8 {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 360px) and (max-width: 499px) {
  #center {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .profile-content {
    width: 90%;
    margin-bottom: 64px !important;
  }

  #profile-form {
    margin-top: 24px;
  }

  .profile-header-button {
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 12px 0px !important;
  }

  #Alert-prof {
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
    display: none;
  }

  #update-button {
    margin-bottom: 40px !important;
  }

  #profile-footer-home {
    margin-top: 24px !important;
  }
}

@media (max-width: 375px) {
  .profile-content {
    border-radius: 16px;
    max-height: 1269px;
  }

  .container-sm {
    background-color: transparent;
  }

  #center {
    margin-right: auto;
    margin-left: auto;
    max-width: 450px;
  }

  #profile-form {
    min-height: 1100px !important;
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
  }

  .dropdown {
    position: relative;
    // width: 100%;
    // max-width: 400px;
  }

  .vs__dropdown-menu {
    max-width: 100% !important;
  }

  .dropdown-input,
  .dropdown-selected {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid lightgray;
    background: white;
    line-height: 1.5em;
    outline: none;
    text-align: left;
    margin-bottom: 20px;
  }

  .dropdown-input:focus,
  .dropdown-selected:hover {
    background: #fff;
    border-color: #e2e8f0;
  }

  .dropdown-input::placeholder {
    opacity: 0.7;
  }

  .dropdown-selected {
    font-weight: bold;
    cursor: pointer;
  }

  .dropdown-list {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 136px;
    margin-top: -30px;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    margin: -30px 0 0 15px;
    padding: 16px 16px 16px 16px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #b3b3b3;
    border-radius: 0px 0px 4px 4px;
  }

  // .dropdown-list > * {
  //   flex: 1 1 33%;
  // }
  .dropdown-item {
    display: flex;
    width: 50%;
    padding: 11px 16px;
    cursor: pointer;
    background-color: transparent !important;
  }

  // .dropdown-item:hover {
  //   //background: #edf2f7;
  // }

  .caret {
    font-weight: bolder;
    font-size: 10pt;
    color: gray;
    float: right;
    margin: 0;
    padding: 0;
  }

  #profile-header {
    width: 100%;
    background: #036cae;
    position: relative;
    border-radius: 6px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .profile-footer {
    width: 100%;
    max-height: 50px;
  }

  #update-button {
    width: 159px;
    height: 60px;
    background: #c24c21;
    border: 2px solid #c24c21;
    border-radius: 16px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
  }

  #update-button:hover {
    background: #ffffff;
    border: 2px solid #c24c21;
    color: #c24c21;
  }

  #update-button:active {
    background: #a9431d;
    border: 2px solid #a9431d;
  }

  #homepage-button {
    color: #333333;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    height: 41px;
    width: 217px;
    border: 1px solid #0d2b5a;
    border-radius: 16px;
    background-color: transparent;
    margin-bottom: 56px;
  }

  #homepage-button:hover {
    background-color: #f15a2a;
    border: 2px solid #f15a2a;
    color: #ffffff;
  }

  #homepage-button:active {
    background-color: #f15a2a;
    border: 2px solid #f15a2a;
    color: #ffffff;
  }

  #profile-footer-home {
    text-align: center;
    margin-top: 80px;
    display: block;
  }

  .profile-header-button {
    background: transparent;
    border: none;
    padding: 18px 0px;
    color: #ffffff;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  // .profile-header-button:nth-of-type(1) {
  //   margin-left: 74px;
  // }

  .line {
    outline: none;
    border-bottom: 2px solid #f15a2a;
  }

  .col-8 {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.profile-content {
  border-radius: 16px;
  max-height: 1269px;
}

.container-sm {
  background-color: transparent;
}

#center {
  max-width: 450px;
}

#profile-form {
  //min-height: 1099px !important;
  border-radius: 16px;
  background-color: #fff;
}

.alert {
  padding: 0.75rem 0.25rem;
  white-space: normal !important;
}

#Alert {
  top: 40px;
  margin-left: 16px;
  margin-right: 16px;
}

.dropdown {
  position: relative;
  width: 100%;
}

.vs__dropdown-menu {
  max-width: 100% !important;
}

.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid lightgray;
  background: white;
  line-height: 1.5em;
  outline: none;
  text-align: left;
  margin-bottom: 20px;
}

.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.caret {
  font-weight: bolder;
  font-size: 10pt;
  color: gray;
  float: right;
  margin: 0;
  padding: 0;
}

#profile-header {
  width: 100%;
  background: #036cae;
  position: relative;
  border-radius: 6px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.profile-footer {
  width: 100%;
}

#update-button {
  width: 159px;
  height: 60px;
  background: #c24c21;
  border: 2px solid #c24c21;
  border-radius: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 64px;
}

#update-button:hover {
  background: #ffffff;
  border: 2px solid #c24c21;
  color: #c24c21;
}

#update-button:active {
  background: #a9431d;
  border: 2px solid #a9431d;
}

#homepage-button {
  color: #333333;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
  height: 41px;
  width: 217px;
  border: 1px solid #0d2b5a;
  border-radius: 16px;
  background-color: transparent;
  margin-bottom: 56px;
}

#homepage-button:hover {
  background-color: #f15a2a;
  border: 2px solid #f15a2a;
  color: #ffffff;
}

#homepage-button:active {
  background-color: #f15a2a;
  border: 2px solid #f15a2a;
  color: #ffffff;
}

#profile-footer-home {
  text-align: center;
  margin-top: 80px;
}

.profile-header-button {
  background: transparent;
  border: none;
  padding: 18px 0px;
  color: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.profile-header-button.line {
  font-weight: 700;
}

.line {
  outline: none;
  border-bottom: 2px solid #f15a2a;
}

.col-8 {
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
