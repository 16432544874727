<template>
    <div :style="{ display: showTranslateWidget ? 'inline-block' : 'none '}" id="google_translate_element"></div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    showTranslateWidget: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      //showTranslateWidget: this.showTranslateWidget,
      //translateWidget: null,
      // email: "",
      // password: "",
      // submitted: false,
      // loading: false,
      // lang_stanza_key: "footer",
      // lang_stanza: {},
      // lang_info: {},
    };
  },
  mounted() {
    // if (this.showTranslateWidget) {
    //   this.loadGoogleTranslateScript();
    // }
    console.log("GoogleWidget mounted here ***************()()()()()()()()");
    console.log("Value of the GW0010 showTranslateWidget prop is:", this.showTranslateWidget);
  },
  // async created() {
  //   function googleTranslateElementInit() {
  //     new google.translate.TranslateElement(
  //       {
  //         pageLanguage: "en",
  //         includedLanguages:
  //           "ar,en,es,vi",
  //         layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
  //         gaTrack: true,
  //         gaId: "UA-81851309-1",
  //       },
  //       "google_translate_element"
  //     );
  //   }
  //   googleTranslateElementInit();
  // },
  methods: {
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    // loadGoogleTranslateScript() {
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.src = document.head.appendChild(script);

    //   window.googleTranslateElementInit = this.googleTranslateElementInit;
    // },
    // googleTranslateElementInit() {
    //   new window.google.translate.TranslateElement({pageLanguage: 'en'}, google.translate.element);
    // }
  },
};
</script>
<style scoped>
@media (min-width: 556px) and (max-width: 1000px) {
}
@media (max-width: 555px) {
}
@media (min-width: 360px) and (max-width: 400px) {
}
</style>